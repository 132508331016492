@import "https://use.fontawesome.com/ede1f9233a.css";
@import "https://hello.myfonts.net/count/32ac93";
@import "variables";

@font-face {
  font-family: 'MuseoSansRounded-100';
  src: url('/fonts/1/32AC93_3_0.eot');
  src: url('/fonts/1/32AC93_3_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/1/32AC93_3_0.woff2') format('woff2'),
  url('/fonts/1/32AC93_3_0.woff') format('woff'),
  url('/fonts/1/32AC93_3_0.ttf') format('truetype');
}

@font-face {
  font-family: 'MuseoSansRounded-300';
  src: url('/fonts/1/32AC93_0_0.eot');
  src: url('/fonts/1/32AC93_0_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/1/32AC93_0_0.woff2') format('woff2'),
  url('/fonts/1/32AC93_0_0.woff') format('woff'),
  url('/fonts/1/32AC93_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'MuseoSansRounded-500';
  src: url('/fonts/1/32AC93_2_0.eot');
  src: url('/fonts/1/32AC93_2_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/1/32AC93_2_0.woff2') format('woff2'),
  url('/fonts/1/32AC93_2_0.woff') format('woff'),
  url('/fonts/32AC93_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'MuseoSansRounded-700';
  src: url('/fonts/1/32AC93_5_0.eot');
  src: url('/fonts/1/32AC93_5_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/1/32AC93_5_0.woff2') format('woff2'),
  url('/fonts/1/32AC93_5_0.woff') format('woff'),
  url('/fonts/1/32AC93_5_0.ttf') format('truetype');
}

@mixin button {
  border: 1px solid #12235f;
  padding: .75rem 1.25rem;
  margin-top: .625rem;
  margin-bottom: .625rem;
  color: #fff;
  background: #12235f;
  white-space: normal;
  float: left;
  font-size: 1rem;
  input[type="submit"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    cursor:pointer;
  }
}


//Kleuren
$blauw: rgb(18, 35, 95);
$mediumblauw: rgb(47, 99, 170);
$lichtblauw: rgb(0, 157, 224);

$grijs: rgb(51, 51, 38);
$lichtgrijs: rgb(160, 160, 140);
$lichtlichtgrijs: rgb(240, 240, 235);

$voetlichtgrijs: rgb(217, 217, 208);
$voetgrijs: rgb(105, 105, 91);

h2 a {
  color: $lichtblauw;
}

a {
  color: $blauw;
  a:hover {
    color: $lichtblauw;
  }
}

p {
  a {
    color: $blauw;
  }
  a:hover {
    color: $lichtblauw;
  }
}

h4, h6 {
  color: #333326;
}

h3 {
  a {
    color: $lichtblauw;
  }
  a:hover {
    color: $lichtblauw;
  }
}

p {
  line-height: 1.4;
}
section {
  padding:0.5em;
}

.home {
  .slider.variant1 .slide {
    padding: 17.1875rem 0 17.1875rem 0;
    position: relative;
    background-size: cover !important;
    min-height: 43.75rem;

    .caption {
      background: none;
      h3 {
        text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.48);
        font-size: 5vw;
        text-align: center;
        color: #fff;
        font-family: 'MuseoSansRounded-700', sans-serif;
      }
    }
  }
}

body.Deltalinqs_News_Sidebar_Right {
  section {
    padding:1rem;
  }
}


.slider.variant1 .slide {
  /*min-height:43.75rem;*/
}

.slider.variant1 .slide {
  /*min-height: 31.25rem;
  padding: 28.25rem 0 0 0;*/
  .caption {
    h3 {
      text-align: center;
      color: #fff;
      font-family: 'MuseoSansRounded-700', sans-serif;
    }
  }
}

.slider.variant1 .slick-dots li button {
  display: none;
}

.header.variant1 .bottomWrapper, .highlightRow {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.49);
}

.footer.variant1 .top {
  border-bottom: 1px solid $lichtblauw;
  padding-top: 15px;
}


ul.dropdown li a {
  font-weight: normal !important;
  font-family: 'MuseoSansRounded-300', sans-serif !important;
}

/*Ledenzoek*/
.memberSearch.variant1 .GoogleMapResult_Grid .memberSearchMember h5 {
  font-size:1.1em;
  font-weight:normal;
  margin-top:15px;
}

/* .highlightRow {
  background-image:url("http://www.deltalinqs.nl/sites/www.deltalinqs.nl/files/styles/megaachtergrond/public/138849-shell-pernis-avond-29_bewerkt_01.jpg?itok=d7GbkyYF");
  background-size:cover;
  background-position: center center;
  background-repeat:no-repeat;
} */

.highlightRow .meetingsSmall.variant1 {
  padding: 25px;
  border-bottom: 0px solid #FFF !important;
  h3 {
    color: $lichtblauw;
  }
  article {
    border-bottom: 0px solid #FFF !important;
  }
}

.highlightRow .text.variant1.Keuze {
  padding: 25px;
  background-color: #f0f0eb;
  h3 {
    color: $lichtblauw;
  }
}

.fullWidth {
  border: none !important;
  margin-bottom: 20px;
}

img {
  margin-bottom: 10px;
}

.home img {
  margin-bottom: 0;
}

.home .fullWidth {
  margin-bottom: 0px;
}

.drieKoppen {
  background-color: #fff;
  border: 4px solid $lichtblauw;
  padding: 25px;
  text-align: center;
  margin: 20px;

  h3 {
    color: $lichtblauw;
    font-weight: 700;
  }

  h3 a {
    color: $lichtblauw;
  }
  h3 a:hover {
    color: $lichtblauw;
  }

  p {
    font-weight: 700;
    color: $blauw;
    padding: 20px;
  }

}

.block_pages_variant3 {
  .intro {

    text-align: center;
  }
}

/*
.home #CCIPlaceHolder_Row_4 {
  background-image: url("http://www.deltalinqs.nl/sites/www.deltalinqs.nl/files/styles/megaachtergrond/public/138849-shell-pernis-avond-29_bewerkt_01.jpg?itok=d7GbkyYF");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 800px;
  .row {
    background: none;
  }
  .text {
    //background-color: #fff;
  }
  .intro h4 {
    color: #fff;
    font-weight: 700;
    text-align: center;
    text-shadow: 4px 4px 6px rgba(0, 0, 0, .48);
  }
}*/

.home #Row_2_Block_1_CCIPlaceHolder_Block_Pages_Variant_3 .pages.variant3 .intro {
  h3 {
    color: $blauw;
  }
}

.pages.variant3 .grid-item article {
  border: none;
}

.pages.variant3 .grid-item article .afbeelding {
  height: 250px;
}

.afbeelding.links {
  text-align:left;
}

.pages.variant3 .grid-item article .text {
  background-color: #f0f0eb;
  h4 a {
    color: $lichtblauw;
  }
}

.pages.variant3 .grid-item article .text time {
  color: #333326;
  font-weight: bold;
}

.pages.variant3 .grid-item:hover {
  time {
    color: #FFF !important;
  }
}

.footer.variant1 .submitveld input {
  background-color: $lichtblauw;
}

.cijfers .row {
  padding-top: 250px;
  h4 {
    color: #fff;
    font-family: 'MuseoSansRounded-100', sans-serif;
    font-size: 1.86em;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.53);
  }
  h2 {
    color: #fff;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.53);
    font-size: 6vw;
    font-weight: 700;
  }
}

.home #CCIPlaceHolder_After_Content .row {
  background-color: $lichtblauw;
  .cijfers {
    background-color: $lichtblauw;
  }
}

.home #CCIPlaceHolder_After_Content {
  background-color: $lichtblauw;
}

.home #homepage-blocks {
  .columns {
    min-height: 175px;
  }
}

table.documentsTable {
  td.docIcon {
    display:none;
  }
}

.mySubscribedMeetings.variant1 ul li time {
  color: $lichtblauw;
}

.mySubscribedMeetings.variant1{
  font-weight: bold;
  color: $lichtblauw;
}

.block.mySubscribedMeetings.variant1 h3, .block.myPersonalInformation.variant1 h3 {
  /*font-weight: bold;*/
  color: $lichtblauw;
}

.block.changePassword.variant1 h3 {
  color: $blauw;
  /*font-weight: bold;*/
}

#Row_2_Block_MySubscribedMeetings .item.last {
  color: #2f2f2f;
}

.mySubscribedMeetings.variant1 ul li {
  font-weight:normal;
}

.mySubscribedMeetings.variant1 ul li time {
  color: $blauw;
}

#Row_2_Block_MySubscribedMeetings {
  display: block !important;
}

#Block_Footer_Block_1_Search_searchbox {
  width: 35%;
}

.letter {
  color: $lichtblauw;
}

.Iconen {
  .columns {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 40em) {
  #homepage-blocks {
    display: none;
  }

}

// icoon voor domein titel
form[action*='aspx?id=81'] {
  h1:before {
    content: url(/img/1/components/Icons/veiligheidDB.png);
    padding-right: 10px;
    vertical-align: middle;
    display: inline-block;
    margin-left:-5px;
  }
}

form[action*='aspx?id=117'] {
  h1:before {
    content: url(/img/1/components/Icons/ondernemersDB.png);
    padding-right: 10px;
    vertical-align: middle;
    display: inline-block;
    margin-left:-5px;
  }
}

form[action*='aspx?id=118'] {
  h1:before {
    content: url(/img/1/components/Icons/infraDB.png);
    padding-right: 10px;
    vertical-align: middle;
    display: inline-block;
    margin-left:-5px;
  }
}

form[action*='aspx?id=119'] {
  h1:before {
    content: url(/img/1/components/Icons/innovatieDB.png);
    padding-right: 10px;
    vertical-align: middle;
    display: inline-block;
    margin-left:-5px;
  }
}

form[action*='aspx?id=120'] {
  h1:before {
    content: url(/img/1/components/Icons/duurzaamDB.png);
    padding-right: 10px;
    vertical-align: middle;
    display: inline-block;
    margin-left:-5px;
  }
}

form[action*='aspx?id=121'] {
  h1:before {
    content: url(/img/1/components/Icons/onderwijsDB.png);
    padding-right: 10px;
    vertical-align: middle;
    display: inline-block;
    margin-left:-5px;
  }
}

form[action*='id=161'] {
  .home #CCIPlaceHolder_Row_4 {
    background-image: none !important;
  }
}

h1, h2, h3, h5 {
  color: $lichtblauw;
}

/*Styling medewerkers pagina*/
.Caption{
  text-align: center;
  width: 100%;
  background: rgba(0,157,224,0.7);
  color: #FFF;
  padding: 1em 0;
  margin-bottom: 0;
  z-index: 4;
  position: relative;
  h3 {
    color:#fff;
  }
}
body.partners {
  .columns.partners {
    padding-left: 0px;
    padding-right: 0px;
  }
  section.even {
    padding: 25px;
    background-color: #f0f0eb;
  }
  section.odd {
    padding: 25px;
    background-color: #fff;
  }

  .columns.partners {
    .left {
      padding-right: 20px;
      width: 30%;
      padding-top: 7px;
    }
  }
  .columns.partners {
    .right {
      width: 70%;
    }
  }
}
.no-margin {
  padding: 0px;
}
.Main_title {
  text-align: center;
  width: 100%;
  background: rgba(0, 157, 224, 0.7);
  color: #FFF;
  padding: 1em 0;
  margin-bottom: 20px;
  h3 {
    color: #fff;
  }
}
ul#personen{
  li.person:nth-child(2n) {
    background-color:#f0f0eb;
  }
  li.person {
    p {font-weight: 300;  font-size: 16.1px;  color: #333326;  font-family: "museo-sans-rounded",sans-serif;}
    border-bottom: 3px solid #a0a08c; padding:0;
    #margin {padding:25px;height:260px;}
  }
}


/*Styling partner pagina*/
#margin-partner {
  padding:25px;
  h4 {
    color:#009de0;
  }
}
ul#partners {
  .personImg {
    padding: 25px 0px 0px 25px;
  }
  li.person {
    border-bottom:none;
  }
  li.person:nth-child(3n+1) {
    background-color:#f0f0eb;
  }
}
@media only screen and (max-width: 40em) {
  .columns.partners {
    .left {
      width: 100% !important;
    }
    .right {
      width: 100% !important;
    }
  }
}

/*Nieuwsoverizhct*/
#Numbers {
  padding: 2em 0;
  background: #d9d9d0;
  color: #69695b;
  div {
    background:#d9d9d0;
  }
}
span.next {
  color:#69695b;
  font-weight: bolder;
  a {
    color:#69695b;
    font-weight: bolder;
  }
}
span.prev{
  color:#69695b;
  font-weight: bolder;
  a {
    color:#69695b;
    font-weight: bolder;
  }
}
span.active {
  display: inline-block;
  padding: 0;
  width: 30px;
  text-align: center;
  background: #69695b;
  border: 2px solid #69695b;
  margin: 0 0.25em;
  color:#fff;
}
span.page {
  display: inline-block;
  padding: 0;
  width: 30px;
  text-align: center;
  background: #FFF;
  border: 2px solid #69695b;
  margin: 0 0.25em;
}
.block.general.basic .columns {padding:0px;}

div#Block_CCIPlaceHolder_Block_News {
  .small-12.medium-3.large-3.columns:nth-of-type(even) {
    background-color:#f0f0eb;
    .row {
      background-color:#f0f0eb;
    }
  }
  margin-top:-20px;
  div#main-message {
    min-height:0em;
    padding:5%;
  }
  .main-image img {
    width:100%;
  }
  .main-item {
    span.datum {
      font-weight:700;
      color: #333326;
    }
    h2 {
      font-size: 1.2em;
    }
    .row .columns {
      padding:0px;
    }
    img {
      margin-bottom:0;
    }
  }
  .block.general.basic {
    min-height:600px;
  }
  div#read-more {
    border-bottom: 3px solid #a0a08c;
    position: absolute;
    bottom: 0px;
    a {
      float:right;
      width: 25px;
      height: 25px;
      background: #a0a08c;
      color: #FFF;
      text-align:center;
      font-weight:bold;
    }
  }
  .blockWrapper {
    .text {
      padding: 5%;
      span.datum {
        font-weight: 700;
        color: #333326;
      }
      h2 {
        font-size: 1.2em;
      }
    }
  }
}
/*Verbergen 4e nieuws item homepage*/
div#homepage-message:nth-of-type(1n+4) {
  display:none;
}
div#homepage-news {
  .fa {
    display: inline-block!important;
    overflow: hidden;
    background: #a0a08c;
    color: #FFF;
    text-decoration: none;
    font-size: 15px;
    line-height: 25px;
    text-indent: 10px;
    width: 26px;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 25px;
  }
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 3em;
  div#uitgelicht {
    padding-left:0px!important;
    padding-right:0px!important;
    .columns {
      padding-left:0px;
      padding-right:0px;
    }
  }
  div#homepage-message {
    padding-left:0px!important;
    padding-right:0px!important;
    .columns {
      padding-left:0px;
      padding-right:0px;
    }
  }
  div#homepage-message:nth-of-type(2) {
    background-color:#f0f0eb;
  }
  .text {
    padding:25px;
    h2 {
      margin-top:0px;
      font-size:1.2em;

    }
  }
  span.datum {
    color: #333326;
    font-weight:700;
  }
}

.Blok-News {
  h2.titel {
    margin: 1em 0;
    text-align: center;
    color: #12235f;
    font-size: 1.61491em;
    font-family: "museo-sans-rounded",sans-serif;
    font-weight: 700;
  }
}

h1.title_newsdetail {
  background: rgba(0,157,224,0.7);
  text-align:center;
  line-height:75px;
  margin-top: 0px;
  color:#fff;
}
div#STSLedenzoekForm {
  .telefoon, .email {
    display:none;
  }
}
body.Deltalinqs_News_Sidebar_Right {
  div#CCIPlaceHolder_Sidebar img {
    max-width: 270px;
  }
}

// Hide specific workgroup person info
div[class*="personPersonalInformation"]{
  div[class*="Prive-Medewerker-Vrijveld1"], [class*="Prive-Medewerker-Vrijveld2"], [class*="Prive-Medewerker-Fax"], [class*="Prive-Medewerker-Startdatum"], [class*="Prive-Medewerker-Functie"], [class*="Prive-Medewerker-Email"], [class*="Prive-Medewerker-Mobiel"] {
    display:none!important;
  }
}

#workgroupMembersList{
  display: block;
  &.memberList {
    height: 350px;
    overflow: hidden;
  }
  &.moreMembers{
    transition: height 0.2s;
    height: 100%;
  }
}
#toggleMemberlist{
  margin: 0 0 20px 0;
}

// Change profile URL
div#Url_Wijzigen_panel {
  padding:25px 0;
  .veld.messsages {
    display:none;
  }
  span#Url_Wijzigen_custValNewPostfix {
    color:red;
  }
  input[name="Url_Wijzigen$submit"]{
    @include button;
  }
}


.workgroupSections {
  width:60%;
  @media #{$medium-down} {
    width:100%;
  }
}

.accordion .accordion-navigation > a, .accordion dd > a {
  background-color:#fff;
  span {
    color: #009de0;
    font-weight: bold;
  }
}


p img {
  display:block;
}

.image-centered {
  display:block;
  float:none !important;
  margin:10px auto !important;
}

.image-left {
  float:left !important;
  margin:10px 20px 10px 0 !important;
}

.image-right {
  float:right !important;
  margin:10px 0 10px 20px !important;
}